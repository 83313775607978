<template>
    <div class="Staff" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input v-model.trim="form.search" placeholder="姓名/手机号/用户名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="员工类型">
                            <el-select placeholder="员工类型" v-model="form.type">
                                <el-option label="请选择" value="" />
                                <el-option label="管理人员" value="0" />
                                <el-option label="业务人员" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所属机构组" label-width="120">
                            <el-select
                                filterable
                                :loading="loadingDeptGroupFlag"
                                v-model="form.deptGroupCode"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="group in organizationGroup"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <ef-dept-type-and-dept-select
                        dept-label="归属机构"
                        @selectDept="setDept"
                        @selectDeptType="setDeptType"
                        :componentLoadingCompleteFlag.sync="deptSelectComponentLoadingCompleteFlag"
                    />
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.system.staff.open')"
                >查询
            </el-button>
            <el-button type="primary" @click="handleCreate" size="small" v-if="hasPrivilege('menu.system.staff.create')"
                >新建
            </el-button>
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.system.staff.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                <el-table-column label="姓名" width="80" prop="realName" v-if="showColumn('realName')" />
                <el-table-column label="用户名" width="120" prop="username" v-if="showColumn('username')" />
                <el-table-column label="手机号" width="100" prop="mobile" v-if="showColumn('mobile')" />
                <el-table-column label="性别" width="50" prop="sex" v-if="showColumn('sex')" key="sex">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sex | sex }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="年龄" width="50" prop="age" v-if="showColumn('age')" />
                <el-table-column label="身份证号" width="135" prop="idNumber" v-if="showColumn('idNumber')" />
                <el-table-column label="员工类型" width="70" prop="type" v-if="showColumn('type')" key="type">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | type }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="所属机构组"
                    width="200"
                    prop="staffGroups"
                    align="center"
                    v-if="showColumn('deptType')"
                >
                    <template slot-scope="scope">
                        <p v-for="item in scope.row.staffGroups" :key="item.deptGroupName">{{ item.deptGroupName }}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="机构类型"
                    width="70"
                    prop="deptType"
                    v-if="showColumn('deptType')"
                    key="deptType"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.deptType | deptType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="归属机构" width="140" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="绑定角色" min-width="150" prop="roles" v-if="showColumn('roles')" key="roles">
                    <template slot-scope="scope">
                        <span>{{ scope.row.roles | roles }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="收银机整单最大折扣"
                    width="70"
                    prop="discount"
                    v-if="hasPrivilege('biz.system.staffCash.show') && showColumn('discount')"
                />
                <el-table-column
                    label="收银商品单价最大折扣"
                    width="70"
                    prop="priceDiscount"
                    v-if="hasPrivilege('biz.system.staffCash.show') && showColumn('priceDiscount')"
                />
                <el-table-column
                    label="临时变更会员折扣最大值"
                    width="70"
                    prop="memberDiscountLimit"
                    v-if="hasPrivilege('biz.system.staffCash.show') && showColumn('memberDiscountLimit')"
                />
                <el-table-column
                    label="操作"
                    min-width="350"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleEdit(scope.row)"
                            v-if="hasPrivilege('menu.system.staff.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="handleAssignRole(scope.row)"
                            v-if="hasPrivilege('biz.roleStaff.query')"
                            >分配角色
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleClose(scope.row)"
                            v-if="$store.state.session.code == 'TRSSTF00000000000001'"
                            :disabled="scope.row.code == 'TRSSTF00000000000001'"
                            >关 闭
                        </el-button>
                        <el-button
                            v-if="hasPrivilege('biz.system.staffCash.edit')"
                            size="mini"
                            @click="handleSetCashRegister(scope.row)"
                            :disabled="!(scope.row.canDiscount == 1)"
                            >收银机设置
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog title="分配员工角色" :visible.sync="assignRole.show" :close-on-click-modal="false">
            <el-checkbox-group v-model="assignRole.bindCodes" :disabled="!hasPrivilege('biz.roleStaff.edit')">
                <el-form>
                    <el-card shadow="never">
                        <el-form-item>八号地官方角色</el-form-item>
                        <el-row>
                            <el-col :span="8" v-for="item in assignRole.publicRoles" :key="item.code">
                                <el-form-item>
                                    <el-checkbox :label="item.code">{{ item.name }} </el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card shadow="never" v-if="assignRole.isAdmin === 4">
                        <el-form-item>八号地内部角色</el-form-item>
                        <el-row>
                            <el-col :span="8" v-for="item in assignRole.privateRoles" :key="item.code">
                                <el-form-item>
                                    <el-checkbox :label="item.code">{{ item.name }} </el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card shadow="never" v-if="assignRole.isAdmin === 3">
                        <el-form-item label="机构组角色"
                            ><el-select
                                filterable
                                :loading="loadingDeptGroupFlag"
                                v-model="assignRole.deptCode"
                                :value="assignRole.deptCode"
                                placeholder="请选择"
                                @change="checkGroup"
                            >
                                <el-option
                                    v-for="group in deptGroup"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                >
                                </el-option>
                            </el-select>
                            <el-row>
                                <el-col :span="8" v-for="item in assignRole.deptRoles" :key="item.code">
                                    <el-form-item>
                                        <el-checkbox :label="item.code">{{ item.name }} </el-checkbox>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>
                </el-form>
            </el-checkbox-group>
            <div slot="footer">
                <el-button @click="assignRole.show = false" size="small">取 消</el-button>
                <el-button
                    type="primary"
                    @click="handleAssignRoleOk"
                    size="small"
                    v-if="hasPrivilege('biz.roleStaff.edit')"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <staff-cash-edit ref="staffCash" @completed="staffCashEdit" />
    </div>
</template>

<script>
import Util from '../../../js/Util';

import UrlUtils from '../../../js/UrlUtils';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import StaffCashEdit from './staff/StaffCashEdit';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'Staff',
    mixins: [CheckTableShowColumn],
    components: { StaffCashEdit, EfDeptTypeAndDeptSelect, CheckTableShowColumnDialog },
    data() {
        return {
            form: {
                deptCode: '',
                deptType: '',
                type: '',
                search: '',
                deptGroupCode: '',
                page: 1,
                limit: 20,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/system/staff/page',
                delete: '/system/staff/disable',
                staffRoleMinWeight: '/system/staff/roleMinWeightRole',
            },
            assignRole: {
                show: false,
                url: {
                    boundRoles: '/system/role/staffRelatedRoles',
                    bind: '/system/staff/bind/',
                    findUserInGroup: '/system/role/findUserInGroup',
                },
                deptType: '',
                currentStaffCode: '',
                bindCodes: [],
                backUp: [],
                metaRoles: [],
                deptCode: '',
                staffGroups: [],
                isAdmin: 0,
                publicRoles: [],
                privateRoles: [],
                deptRoles: [],
            },
            organizationGroup: [],
            deptGroup: [],
            loadingDeptGroupFlag: true,
            staffRoleMinWeight: Number.MAX_VALUE,
            deptSelectComponentLoadingCompleteFlag: false,
        };
    },
    mounted() {
        this.handleNowStaffRoleMinWeight();
        UrlUtils.AllRoles(this, (rst = []) => {
            this.assignRole.metaRoles = rst.filter((e) => {
                //过滤掉超级管理员之类的角色
                return !(Util.isEmpty(e.deptType) && e.weight === 0) && e.code !== 'TDSROL00000000000001';
            });
        });
        UrlUtils.DeptGroup(this, (rst) => {
            this.organizationGroup = rst || [];
            this.deptGroup = JSON.parse(JSON.stringify(rst)) || [];
            this.assignRole.deptCode = this.deptGroup[0].code;
            this.loadingDeptGroupFlag = false;
            this.organizationGroup.unshift({
                name: '请选择',
                code: '',
            });
        });
        Util.setIntervalAndTimeout(
            () => {
                return this.form.deptCode !== null;
            },
            () => {
                return true;
            },
            () => {
                this.handleQuery();
            },
            3000
        );
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    computed: {},
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleQuery() {
            if (!this.deptSelectComponentLoadingCompleteFlag) {
                //只要有一个false 就代表有组件未加载完成
                return;
            }
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleNowStaffRoleMinWeight() {
            UrlUtils.QueryRemote(this, this.url.staffRoleMinWeight, (rst) => {
                this.staffRoleMinWeight = Util.isEmpty(rst) ? Number.MAX_VALUE : rst;
            });
        },
        handleCreate() {
            Util.nameJump(this, 'menu.system.staff.create', ['系统管理', '员工管理', '创建员工']);
        },
        handleExport() {
            UrlUtils.Export(this, '员工', '/system/staff/export', this.form);
        },

        handleEdit(row) {
            Util.nameJump(this, 'menu.system.staff.edit', ['系统管理', '员工管理', '编辑员工'], {
                form: row,
                code: row.code,
            });
        },
        handleAssignRole(row) {
            const _this = this;
            this.assignRole.currentStaffCode = row.code;
            UrlUtils.QueryRemote(this, this.assignRole.url.boundRoles + '?staffCode=' + row.code, (rst) => {
                _this.assignRole.bindCodes = rst.map((r) => r.code);
                _this.assignRole.backUp = JSON.parse(JSON.stringify(_this.assignRole.bindCodes));
                _this.checkGroup(_this.assignRole.deptCode);
                _this.assignRole.show = true;
            });
        },
        checkGroup(e) {
            const _this = this;
            UrlUtils.QueryRemote(
                this,
                this.assignRole.url.findUserInGroup +
                    '?deptCode=' +
                    e +
                    '&userStaffCode=' +
                    _this.assignRole.currentStaffCode,
                (rst) => {
                    _this.assignRole.isAdmin = Object.getOwnPropertyNames(rst).length;
                    _this.assignRole.publicRoles = rst[0] || [];
                    _this.assignRole.privateRoles = rst[1] || [];
                    _this.assignRole.deptRoles = rst[2] || [];
                }
            );
        },
        async handleAssignRoleOk() {
            const p = {
                checked: this.assignRole.bindCodes.filter((r) => !this.assignRole.backUp.includes(r)),
                unchecked: this.assignRole.backUp.filter((r) => !this.assignRole.bindCodes.includes(r)),
            };
            const privateCodes = this.assignRole.privateRoles.map((r) => r.code);
            const havePrivateCode = p.checked.concat(p.unchecked).filter((r) => privateCodes.includes(r));
            if (havePrivateCode.length > 0) {
                await this.$confirm(
                    '本次分配角色中，包含八号地内部角色，涉及核心功能，是否确定要分配给该员工',
                    '提示',
                    {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                )
                    .then(() => {
                        UrlUtils.PatchRemote(
                            this,
                            this.assignRole.url.bind +
                                this.assignRole.currentStaffCode +
                                '?sources=' +
                                this.assignRole.deptCode +
                                '&userStaffCode=' +
                                this.assignRole.currentStaffCode,
                            p,
                            null,
                            () => {
                                this.handleQuery();
                                this.$message.success('保存成功');
                                this.assignRole.show = false;
                            }
                        );
                    })
                    .catch(() => {
                        return;
                    });
            } else {
                UrlUtils.PatchRemote(
                    this,
                    this.assignRole.url.bind +
                        this.assignRole.currentStaffCode +
                        '?sources=' +
                        this.assignRole.deptCode +
                        '&userStaffCode=' +
                        this.assignRole.currentStaffCode,
                    p,
                    null,
                    () => {
                        this.handleQuery();
                        this.$message.success('保存成功');
                        this.assignRole.show = false;
                    }
                );
            }
        },
        handleClose(row) {
            UrlUtils.DeleteRemote(this, this.url.delete, row.code);
        },
        handleSetCashRegister(row) {
            const _this = this;
            const _param = {
                staffCode: row.code,
                discount: row.discount,
                priceDiscount: row.priceDiscount,
                memberDiscountLimit: row.memberDiscountLimit,
            };
            _this.$refs.staffCash.open(_param);
            /* this.$prompt('该员工收银机整单最低折扣', '提示', {
                    inputValue: row.discount,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^\d{1}(\.?\d{1})?$/,
                    inputErrorMessage: '数字格式不正确',
                    closeOnClickModal: false
                }).then(({value}) => {
                    if (!Number.parseFloat(value)) {
                        _this.$message.error("数字格式不正确");
                        return;
                    }
                    UrlUtils.PostRemote(this, "/system/staffCash/discount/saveUpdate",
                        {
                            staffCode: row.code,
                            discount: value
                        }, null, () => {
                            this.$message.success("保存成功");
                            _this.handleQuery()
                        });
                });*/
        },
        staffCashEdit() {
            this.handleQuery();
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
    },
    filters: {
        roles(roles) {
            return roles ? roles.map((r) => r.name).join(',') : '';
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '1') {
                return '业务型';
            }
            if (type == '0') {
                return '管理型';
            }
        },
    },
};
</script>

<style scoped>
.Staff .el-form-item {
    margin-bottom: 0;
}
</style>
